const DocumentStatus = {
  SIGNED_BY_BOTH: 'Signed by both parties',
  AWAITING_COUNTERPARTY_TO_BE_READY: 'Waiting for the other party to be ready',
  AWAITING_TO_BE_READY: 'Waiting for you to be ready',
  AWAITING_COUNTERPARTY: 'Waiting for the other party to sign',
  READY_TO_SIGN: 'Ready for your signature',
  NEED_REFERENCES: 'Add references for this document',
  FAILED_TO_SIGN: 'Failed to sign',
};

const getDocumentStatus = ({
  buyerSigned,
  sellerSigned,
  readyToSignBuyer,
  readyToSignSeller,
  role,
  sellerReferenceText,
  buyerReferenceText,
  error,
}: {
  buyerSigned: boolean;
  sellerSigned: boolean;
  readyToSignBuyer: boolean | null;
  readyToSignSeller: boolean | null;
  role: 'BUYER' | 'SELLER';
  sellerReferenceText: string;
  buyerReferenceText: string;
  error: string | null;
}) => {
  const isBuyer = role === 'BUYER';
  const signedByBoth = buyerSigned && sellerSigned;
  const mySigned = isBuyer ? buyerSigned : sellerSigned;
  const readyToSignMe = isBuyer ? !!readyToSignBuyer : !!readyToSignSeller;
  const readyToSignCounterparty = isBuyer
    ? !!readyToSignSeller
    : !!readyToSignBuyer;

  let status;
  let canDeleteOrSelect = true;
  let showCheckbox = true;
  const missingReferences = !sellerReferenceText && !buyerReferenceText;

  if (error) {
    status = DocumentStatus.FAILED_TO_SIGN;
    canDeleteOrSelect = true;
    showCheckbox = false;
  } else if (missingReferences) {
    status = DocumentStatus.NEED_REFERENCES;
    canDeleteOrSelect = true;
    showCheckbox = false;
  } else if (signedByBoth) {
    status = DocumentStatus.SIGNED_BY_BOTH;
    canDeleteOrSelect = false;
    showCheckbox = false;
  } else if (!readyToSignMe) {
    status = DocumentStatus.AWAITING_TO_BE_READY;
  } else if (!readyToSignCounterparty) {
    status = DocumentStatus.AWAITING_COUNTERPARTY_TO_BE_READY;
  } else if (!mySigned) {
    status = DocumentStatus.READY_TO_SIGN;
  } else {
    status = DocumentStatus.AWAITING_COUNTERPARTY;
    canDeleteOrSelect = false;
    showCheckbox = false;
  }

  return { status, canDeleteOrSelect, showCheckbox };
};

export { DocumentStatus, getDocumentStatus };
