import { MatrixResult } from './types';

export const isWithinOrderedMatrix = (
  value: number,
  matrix: number[][] | null,
): matrix is number[][] => {
  if (matrix == null) {
    return false;
  }
  return value <= matrix[0][1] && value >= matrix[matrix.length - 1][1];
};

export const getValue = (matrixPoint: number[]): number => {
  return matrixPoint[1];
};

export const getRate = (matrixPoint: number[]): number => {
  return matrixPoint[0];
};

export const getDuration = (matrixPoint: number[]): number => {
  return matrixPoint[2];
};

// Finds the closest value in the matrix to the value
// The matrix is ordered by the purchase price in the 2nd column and is in decreasing order
// The value returned is the first column of the matrix
export const binarySearchMatrix = (
  matrix: number[][],
  value: number,
): MatrixResult => {
  let left = 0;
  let right = matrix.length - 1;

  while (left <= right) {
    const mid = Math.floor((left + right) / 2);
    const midValue = getValue(matrix[mid]);

    if (midValue === value) {
      return {
        rate: getRate(matrix[mid]),
        duration: getDuration(matrix[mid]),
      };
    } else if (midValue < value) {
      right = mid - 1;
    } else {
      left = mid + 1;
    }
  }

  // When the loop exits, left is the index of the closest value less than the target
  // and right is the index of the closest value greater than the target
  if (left >= matrix.length) {
    return {
      rate: getRate(matrix[right]),
      duration: getDuration(matrix[right]),
    };
  }
  if (right < 0) {
    return {
      rate: getRate(matrix[left]),
      duration: getDuration(matrix[left]),
    };
  }

  const leftValue = getValue(matrix[left]);
  const leftDuration = getDuration(matrix[left]);
  const rightValue = getValue(matrix[right]);
  const rightDuration = getDuration(matrix[right]);
  const leftData = getRate(matrix[left]);
  const rightData = getRate(matrix[right]);

  if (leftValue === rightValue) {
    return {
      rate: leftData,
      duration: leftDuration,
    };
  }

  // Perform linear interpolation
  const weightLeft = (rightValue - value) / (rightValue - leftValue);
  const weightRight = (value - leftValue) / (rightValue - leftValue);

  return {
    rate: leftData * weightLeft + rightData * weightRight,
    duration: leftDuration * weightLeft + rightDuration * weightRight,
  };
};
