import React, { useMemo } from 'react';

import { handleOnchange } from './input.utils';
import styles from './styles.module.scss';

type InputProps = {
  type?: 'text' | 'password' | 'number';
  disabled?: boolean;
  placeholder?: string;
  size: 'small' | 'medium' | 'large' | 'extra-large';
  label?: string | React.ReactNode;
  labelElements?: React.ReactElement;
  value: string | number;
  className?: string;
  min?: number;
  max?: number;
  message?: string;
  children?: React.ReactNode;
  Icon?: any;
  textAlign?: 'left' | 'right';
  iconPosition?: 'left' | 'right' | 'both';
  variant: 'primary' | 'success' | 'reject' | 'dark' | 'cashFlow';
  suffix?: string | React.ReactElement;
  [key: string]: any;
};

const defaultInputClasses = `border-none focus:border-none focus:outline-none ${styles.defaultInput}`;
const inputFontClasses = {
  primary: '',
  success: '',
  reject: '',
  dark: '',
  cashFlow: 'font-mono',
};
const inputSizeClasses = {
  small: `text-[14px] w-[100%] leading-[20px]  h-[20px] ${defaultInputClasses}`,
  medium: `text-[14px]  w-[100%] leading-[20px] h-[20px] ${defaultInputClasses}`,
  large: `text-[14px]  w-[100%] leading-[20px]  h-[20px]  ${defaultInputClasses}`,
  'extra-large': `text-[16px] w-[100%] leading-[24px] h-[24px] ${defaultInputClasses}`,
};
const inputSuffixSizeClasses = {
  small: `text-[14px] text-gray-300 ps-[2px]`,
  medium: `text-[14px] text-gray-300 ps-[2px]`,
  large: `text-[14px] text-gray-300 ps-[2px]`,
  'extra-large': `text-[16px] text-gray-300 ps-[2px]`,
};

const inputGroupDefaultClass = 'w-[100%] rounded';
const borderColorClasses = {
  primary: 'border border-brand-500 px-4',
  success: 'border border-green-400 px-4',
  reject: 'border border-red-500 px-4',
  dark: 'border border-white px-4',
  cashFlow: 'border border-gray-800 px-4',
};
const inputGroupSizeClasses = {
  small: `h-[28px]  w-[100%] rounded ${inputGroupDefaultClass}`,
  medium: `h-[36px]   w-[100%] rounded ${inputGroupDefaultClass}`,
  large: `h-[40px]  w-[100%] rounded ${inputGroupDefaultClass}`,
  'extra-large': `h-[48px]  w-[100%] rounded ${inputGroupDefaultClass}`,
};

const inputLabelBaseClasses = 'block text-[12px] font-custom-500';
const inputLabelColorClasses = {
  primary: 'text-white',
  success: 'text-white',
  reject: 'text-white',
  dark: 'text-white',
  cashFlow: 'text-slate-400',
};
const inputMessageBaseClasses = 'block text-[12px] font-custom-500';

const disabledVariantClasses =
  'text-grey-800 bg-transparent border border-gray-800 px-4';

const messageColorClasses = {
  primary: 'text-white',
  success: 'text-green-400',
  reject: 'text-red-500',
  dark: 'text-white',
  disabled: 'text-gray-800',
  cashFlow: 'text-slate-400',
};

const iconColorClasses = {
  primary: 'stroke-white',
  success: 'stroke-green-400',
  reject: 'stroke-red-500',
  dark: 'stroke-white',
  disabled: 'stroke-gray-800',
  cashFlow: 'stroke-slate-400',
};

export const UiInput: React.FC<InputProps> = React.memo((props: InputProps) => {
  const {
    type = 'text',
    disabled = false,
    placeholder = '',
    size,
    label,
    labelElements,
    value,
    className,
    min,
    max,
    message,
    Icon,
    textAlign = 'left',
    iconPosition,
    variant = 'primary',
    suffix = '',
    ...rest
  } = props;

  const messageClasses = `${inputMessageBaseClasses} ${messageColorClasses[disabled ? 'disabled' : variant]}`;
  const inputClasses = `${inputSizeClasses[size]} text-${textAlign} ${disabled ? 'text-input-border-disabled' : 'text-gray-300'} ${inputFontClasses[variant]}`;
  const inputGroupClasses = useMemo(
    () => `flex space-between items-center
  ${inputGroupSizeClasses[size]} ${disabled ? disabledVariantClasses : borderColorClasses[variant]}
  ${className}`,

    [variant, className, disabled],
  );

  return (
    <div
      className="flex-column mb-2 items-start justify-between"
      id="inputContainer"
    >
      {label && (
        <label
          className={`${inputLabelBaseClasses} ${inputLabelColorClasses[variant]}`}
        >
          {label}
        </label>
      )}
      {labelElements && labelElements}
      <div id="inputGroup" className={inputGroupClasses}>
        {(iconPosition === 'left' || iconPosition === 'both') && (
          <Icon
            className={`${iconColorClasses[disabled ? 'disabled' : variant]} mr-1`}
          />
        )}
        <input
          type={type}
          disabled={disabled}
          placeholder={placeholder}
          className={inputClasses}
          value={value}
          {...rest}
          onChange={(e) =>
            handleOnchange(e, min, max, rest.onChange, rest?.name)
          }
        />
        {suffix && (
          <span
            className={`${inputSuffixSizeClasses[size]} ${disabled ? 'text-input-border-disabled' : 'text-input-text'}`}
          >
            {suffix}
          </span>
        )}
        {(iconPosition === 'right' || iconPosition === 'both') && (
          <Icon
            className={`${iconColorClasses[disabled ? 'disabled' : variant]} ml-1`}
          />
        )}
      </div>
      {message && <span className={messageClasses}>{message}</span>}
    </div>
  );
});
