import React from 'react';

import {
  faCircleCheck,
  faCircleXmark,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useField, FieldHookConfig } from 'formik';

import { UiInput } from '../../../ui-kit';

const useCustomField = (
  props: FieldHookConfig<string> & { fieldName: string },
) => {
  const [field, meta, helpers] = useField(props);
  const { name, fieldName } = props;

  const customMeta = {
    ...meta,
    error: meta.error?.replace(name, fieldName),
  };

  return [field, customMeta, helpers] as const;
};

interface InputComponentProps {
  name: string;
  value: string | number;
  onChange: (event: React.ChangeEvent) => void;
  onBlur?: (event: React.FocusEvent) => void;
  unit?: string;
  error?: boolean;
  className?: string;
}

interface CashFlowModelingInputProps {
  name: string;
  fieldName: string;
  label: string | React.ReactElement;
  unit?: string;
  CustomInputComponent?: React.ComponentType<InputComponentProps>;
  onBlur?: () => void;
  min?: number;
  max?: number;
}
interface LabelElementProps {
  select: React.ReactNode;
  isValid: boolean;
}

const LabelElement = ({ select, isValid }: LabelElementProps) => (
  <div className="mb-2 flex h-[27px] items-center justify-between">
    {select}
    <div>
      <FontAwesomeIcon
        icon={isValid ? faCircleCheck : faCircleXmark}
        color={isValid ? '#9BDB72' : '#EB5757'}
        className="ml-2"
      />
    </div>
  </div>
);

const CashFlowModelingInput: React.FC<CashFlowModelingInputProps> = ({
  name,
  fieldName,
  label,
  unit = '%',
  min,
  max,
  ...props
}) => {
  const [field, meta] = useCustomField({ name, fieldName, ...props });
  const isValid = !(meta.touched && meta.error);

  return (
    <div className="relative">
      <UiInput
        {...field}
        type={'number'}
        size={'small'}
        labelElements={<LabelElement isValid={isValid} select={label} />}
        variant={!isValid ? 'reject' : 'cashFlow'}
        message={meta.error}
        onBlur={props.onBlur}
        textAlign={'right'}
        suffix={unit}
        max={max}
        min={min}
      />
    </div>
  );
};

export default CashFlowModelingInput;
