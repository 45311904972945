import React from 'react';

import { YieldCurveTableProps } from './types';

const YieldCurveTable: React.FC<YieldCurveTableProps> = ({ data }) => {
  const labels = ['3 Mo', '6 Mo', '1 Yr', '2 Yr', '5 Yr', '10 Yr', '30 Yr'];
  const series = labels.map((label) => ({
    label,
    value:
      data && data.series.Last && data.labels.includes(label)
        ? data.series.Last[data.labels.indexOf(label)] || 0
        : 0,
  }));

  return (
    <div className="flex flex-wrap gap-2 rounded bg-purple-900 p-2">
      {series.map((point, index) => (
        <div
          key={index}
          className="flex items-center gap-2 rounded bg-purple-900 px-4 py-1"
        >
          <span className="text-xs text-gray-500">{point.label}</span>
          <span className="text-xs font-bold text-white">
            {point.value?.toFixed(2) || ''}%
          </span>
        </div>
      ))}
    </div>
  );
};

export default YieldCurveTable;
