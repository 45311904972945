import React, {
  useEffect,
  useState,
  useMemo,
  useCallback,
  memo,
  useContext,
} from 'react';

import { StyledBody } from 'baseui/card';
import { AuthContext } from 'context/AuthContext';
import { diff } from 'deep-diff';
import { generateRowId } from 'features/drilldown/LoanDatatable/LoanTable';
import { KPI } from 'features/pages/portfolio/Dashboard/PoolManager/types';
import { generateMetrics } from 'features/pages/portfolio/PortfolioSummary';
import toast from 'react-hot-toast';

import { useMutation } from '@apollo/client';
import { useQuery } from '@apollo/client';

import { CollateralStatus } from '__generated__/globalTypes';

import {
  DeleteS3File,
  DeleteS3FileVariables,
} from 'mutation/__generated__/DeleteS3File';
import {
  UpdateCollateralStatus,
  UpdateCollateralStatusVariables,
} from 'mutation/__generated__/UpdateCollateralStatus';
import {
  UpdateDiligenceStatus,
  UpdateDiligenceStatusVariables,
} from 'mutation/__generated__/UpdateDiligenceStatus';
import DELETE_S3_FILE_MUTATION from 'mutation/deleteS3File';
import {
  REMOVE_COLLATERAL_FILE,
  UPDATE_COLLATERAL_STATUS,
  UPDATE_DILIGENCE_STATUS,
} from 'mutation/diligenceMutations';

import { GetDealDiligence_deal_diligence_collateral as Collateral } from 'query/__generated__/GetDealDiligence';
import { GetDealDiligence_deal_diligence as DiligenceInfo } from 'query/__generated__/GetDealDiligence';
import {
  GetUserPermissions,
  GetUserPermissionsVariables,
} from 'query/__generated__/GetUserPermissions';
import { GET_USER_PERMISSIONS } from 'query/permissionsQueries';

import { DiligenceCard_DiligenceCard } from './__generated__/DiligenceCard';
import { LoanDiligenceProvider } from './contexts/LoanDiligenceProvider';
import { Diligence, DiligenceProps } from './Diligence';
import { FileDiligenceStatus } from './FileStatus';
import { LoanDiligenceStatus } from './LoanDiligenceStatus';
import { useCollateralNotes } from './queries/useCollateralNotes';
import { useLoans } from './queries/useLoans';
import { useLoansSelectedForDiligence } from './queries/useLoansSelectedForDiligence';
import { ReviewDiligenceProps } from './ReviewDiligenceTab';
import { SelectLoansProps } from './SelectLoansTab';

type DiligenceControllerProps = {
  dealId: string;
} & DiligenceCard_DiligenceCard;

export type LoanId = string;

// A RowId is of the form "accountId:loanId"
export type RowId = string;

const DiligenceController: React.FC<DiligenceControllerProps> = memo(
  ({
    dealId,
    assetClass,
    role,
    is_complete,
    buyer_accepted,
    seller_accepted,
    should_show_summaries_tab,
    deal,
    original_pool_summary,
    accepted_bid_summary,
    post_diligence_summary,
    diligence_reports,
  }) => {
    const [rawUserEmail, setRawUserEmail] = useState('');

    const { state: authState } = useContext(AuthContext);
    const user = useMemo(() => authState.user, [authState.user]);

    const fetchUserAttributes = useCallback(() => {
      if (user) {
        user.getUserAttributes((err, data) => {
          if (err) {
            console.error(err);
            return;
          }

          if (data) {
            const userAttributes = data.reduce(
              (acc, attribute) => {
                acc[attribute.Name] = attribute.Value;
                return acc;
              },
              {} as Record<string, string>,
            );

            setRawUserEmail(userAttributes.email || '');
          }
        });
      }
    }, [user]);

    useEffect(() => {
      fetchUserAttributes();
    }, [fetchUserAttributes]);

    const userEmail = useMemo(() => rawUserEmail, [rawUserEmail]);

    const { data: userData } = useQuery<
      GetUserPermissions,
      GetUserPermissionsVariables
    >(GET_USER_PERMISSIONS, {
      variables: { requestData: { userEmail } },
      skip: !userEmail,
    });

    const userId = useMemo(
      () => userData?.userManagementGetUser?.user?.userID || '',
      [userData],
    );

    const {
      error: loansError,
      data: loansData,
      queryParams: loansQueryParams,
      updateParams: updateLoansParams,
    } = useLoans(dealId, assetClass);

    const loans = useMemo(() => loansData?.deal?.loans || [], [loansData]);
    const totalItems = loansData?.deal?.performance_summary.loanCount;

    const loansPopulationMetrics: KPI[] = generateMetrics(
      loansData?.deal?.performance_summary,
    );

    const [selectedLoansSnapshot, setSelectedLoansSnapshot] = useState<RowId[]>(
      [],
    );

    const selectLoansMetrics = useMetricsSelection(loansPopulationMetrics, []);

    const selectLoansProps: SelectLoansProps = useMemo(
      () => ({
        dealId,
        loans,
        loansError,
        totalLoans: totalItems,
        assetClass,
        selectLoansMetrics,
        queryParams: loansQueryParams,
        updateQueryParams: updateLoansParams,
        selectedLoansSnapshot,
        is_complete,
        buyer_accepted,
        seller_accepted,
        should_show_summaries_tab,
        userId,
      }),
      [
        dealId,
        loans,
        loansError,
        totalItems,
        assetClass,
        selectLoansMetrics,
        loansQueryParams,
        updateLoansParams,
        selectedLoansSnapshot,
        is_complete,
        buyer_accepted,
        seller_accepted,
        should_show_summaries_tab,
        userId,
      ],
    );

    // TODO: Fix re-rendering issue with useLoansSelectedForDiligence. Component is re-rendered to much time
    const {
      data: diligenceLoansData,
      refetch: refetchDiligence,
      queryParams: diligenceQueryParams,
      updateParams: updateDiligenceQueryParams,
    } = useLoansSelectedForDiligence(dealId, assetClass);

    const onJobCompleted = () => {
      console.log('Job completed -- refetching diligence data');
      refetchDiligence();
    };

    // When the diligence data changes, update the selected loans snapshot
    useEffect(() => {
      const rowIds =
        diligenceLoansData?.deal?.diligence?.map((diligence) =>
          generateRowId(diligence.loan.account_id, diligence.loan.id),
        ) || [];

      if (diff(rowIds, selectedLoansSnapshot)) {
        setSelectedLoansSnapshot(rowIds);
      }
    }, [diligenceLoansData, selectedLoansSnapshot]);

    const diligencePopulationMetrics: KPI[] = generateMetrics(
      diligenceLoansData?.deal?.diligence_performance_summary,
    );

    const selectReviewMetrics = useMetricsSelection(
      diligencePopulationMetrics,
      [],
    );

    const [removeCollateralFile] = useMutation(REMOVE_COLLATERAL_FILE);

    const [updateDiligenceStatus] = useMutation<
      UpdateDiligenceStatus,
      UpdateDiligenceStatusVariables
    >(UPDATE_DILIGENCE_STATUS);

    const [updateCollateralStatus] = useMutation<
      UpdateCollateralStatus,
      UpdateCollateralStatusVariables
    >(UPDATE_COLLATERAL_STATUS);

    const [deleteS3File] = useMutation<DeleteS3File, DeleteS3FileVariables>(
      DELETE_S3_FILE_MUTATION,
    );

    const { collateralNotesMap, fetchCollateralNotes } = useCollateralNotes();

    // TODO add upload progress to the UI
    const [uploadProgress, _setUploadProgress] = useState<number>();

    const loanDiligenceIdForLoanId = useCallback(
      (loanId: string): string => {
        const loanDiligence = diligenceLoansData?.deal?.diligence.find(
          (diligence: DiligenceInfo) => diligence.loan.id === loanId,
        );
        return loanDiligence?.id || '';
      },
      [diligenceLoansData],
    );

    const getFileNameAndDiligenceId = useCallback(
      (
        docId: string,
      ): { fileName: string | undefined; diligenceId: string | undefined } => {
        let fileName: string | undefined;
        let diligenceId: string | undefined;

        for (const diligence of diligenceLoansData?.deal?.diligence || []) {
          const collateral = diligence.collateral.find(
            (collateral: Collateral) => collateral.collateralID === docId,
          );
          if (collateral) {
            fileName = collateral.fileName;
            diligenceId = diligence.id;
            break;
          }
        }

        return { fileName, diligenceId };
      },
      [diligenceLoansData],
    );

    const reviewDiligenceProps: ReviewDiligenceProps = useMemo(
      () => ({
        dealId,
        dealName: deal.listing.provided_name || deal.listing.name,
        assetClass,
        role,
        is_complete,
        buyer_accepted,
        seller_accepted,
        should_show_summaries_tab,
        buyerAcceptedDiligence: deal.buyerAcceptedDiligence,
        sellerAcceptedDiligence: deal.sellerAcceptedDiligence,
        reviewDiligenceMetrics: selectReviewMetrics,
        queryParams: diligenceQueryParams,
        updateQueryParams: updateDiligenceQueryParams,
        totalDiligenceItems: diligenceLoansData?.deal?.diligence?.length || 0,
        diligenceData: diligenceLoansData?.deal?.diligence || [],
        handleLoanDiligenceStatusChanged: async (
          loanId: string,
          status: LoanDiligenceStatus,
        ) => {
          const loanDiligenceId = loanDiligenceIdForLoanId(loanId);

          await updateDiligenceStatus({
            variables: {
              id: loanDiligenceId,
              diligenceStatus: status,
            },
            optimisticResponse: {
              updateDiligenceStatus: {
                __typename: 'Diligence',
                id: loanDiligenceId,
                status,
              },
            },
          });
        },
        documents: {
          collateralNotesMap,
          uploadProgress,
          onFileStatusSelect: async (
            dealId: string,
            loanId: string,
            fileName: string,
            status: FileDiligenceStatus,
            callback?: () => void,
          ) => {
            try {
              await updateCollateralStatus({
                variables: {
                  input: {
                    dealID: dealId,
                    loanID: loanId,
                    fileName: fileName,
                    newStatus: toCollateralStatus(status),
                  },
                },
                optimisticResponse: {
                  updateCollateralStatus: true,
                },
              });

              toast.success(
                `The status of the file "${fileName}" has been successfully updated to "${status}".`,
              );

              refetchDiligence();

              if (callback) {
                callback();
              }
            } catch (err) {
              console.error('Error updating file status:', err);

              toast.error(
                `Failed to update the status of the file "${fileName}". Please try again.`,
              );
            }
          },
          onRemoveFile: (
            docId: string,
            fileName: string,
            diligenceId: string,
          ) => {
            removeCollateralFile({
              variables: {
                input: {
                  dealID: dealId,
                  loanID: diligenceId,
                  fileName: fileName,
                },
              },
            })
              .then(() => {
                toast.success(`File "${fileName}" removed successfully!`);
                refetchDiligence();
              })
              .catch((error) => {
                console.error('Error removing file:', error);
                toast.error('Failed to remove the file. Please try again.');
              });
          },
          onSuccessUpload: () => {
            refetchDiligence();
          },
        },
        fetchCollateralNotes,
        userId,
      }),
      [
        dealId,
        deal.listing.provided_name,
        deal.listing.name,
        assetClass,
        role,
        is_complete,
        buyer_accepted,
        seller_accepted,
        should_show_summaries_tab,
        deal.buyerAcceptedDiligence,
        deal.sellerAcceptedDiligence,
        selectReviewMetrics,
        diligenceQueryParams,
        updateDiligenceQueryParams,
        diligenceLoansData,
        updateDiligenceStatus,
        collateralNotesMap,
        uploadProgress,
        updateCollateralStatus,
        deleteS3File,
        refetchDiligence,
        fetchCollateralNotes,
        getFileNameAndDiligenceId,
        loanDiligenceIdForLoanId,
        userId,
      ],
    );

    const diligenceProps: DiligenceProps = useMemo(
      () => ({
        selectLoansProps,
        reviewDiligenceProps,
        role,
        originalPoolSummary: original_pool_summary,
        acceptedBidSummary: accepted_bid_summary,
        postDiligenceSummary: post_diligence_summary,
        diligenceReports: diligence_reports,
        dealId,
        isComplete: is_complete,
        buyerAccepted: buyer_accepted,
        sellerAccepted: seller_accepted,
        should_show_summaries_tab,
        buyerAcceptedDiligence: deal.buyerAcceptedDiligence,
        sellerAcceptedDiligence: deal.sellerAcceptedDiligence,
        userId,
      }),
      [
        selectLoansProps,
        reviewDiligenceProps,
        role,
        original_pool_summary,
        accepted_bid_summary,
        post_diligence_summary,
        diligence_reports,
        dealId,
        is_complete,
        buyer_accepted,
        seller_accepted,
        should_show_summaries_tab,
        deal.buyerAcceptedDiligence,
        deal.sellerAcceptedDiligence,
        userId,
      ],
    );

    return (
      <StyledBody>
        <LoanDiligenceProvider dealId={dealId} onJobCompleted={onJobCompleted}>
          <Diligence {...diligenceProps} />
        </LoanDiligenceProvider>
      </StyledBody>
    );
  },
);

// TODO(kentskinner): after RC1 just used the server types directly rather than mapping back and forth.
export const toCollateralStatus = (
  status: FileDiligenceStatus,
): CollateralStatus => {
  switch (status) {
    case 'In Progress':
      return CollateralStatus.IN_PROGRESS;
    case 'Additional QA Required':
      return CollateralStatus.ADDITIONAL_QA_REQUIRED;
    case 'File Received':
      return CollateralStatus.RECEIVED;
    case 'File Required':
      return CollateralStatus.REQUIRED;
    case 'File Reviewed':
      return CollateralStatus.COMPLETED;
    case 'File Rejected':
      return CollateralStatus.REJECTED;
    default:
      throw new Error(`Invalid status: ${status}`);
  }
};

const useMetricsSelection = (allMetrics: KPI[], initialMetrics: string[]) => {
  const [selectedMetrics, setSelectedMetrics] =
    useState<string[]>(initialMetrics);

  const onAddMetric = (selectedMetric: string) => {
    setSelectedMetrics((metrics) => {
      if (!metrics.includes(selectedMetric)) {
        return [...metrics, selectedMetric];
      } else {
        return metrics;
      }
    });
  };

  const onRemoveMetric = (selectedMetric: string) => {
    setSelectedMetrics((metrics) =>
      metrics.filter((metric) => metric !== selectedMetric),
    );
  };

  return { allMetrics, selectedMetrics, onAddMetric, onRemoveMetric };
};
export default DiligenceController;
