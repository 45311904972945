import React from 'react';

interface FileUploadProgressBarProps {
  fileName?: string;
  progress: number;
  width?: string;
  showFileName?: boolean;
  showFileProgress?: boolean;
  barHeight?: string;
}

export const FileUploadProgressBar: React.FC<FileUploadProgressBarProps> = ({
  fileName,
  progress,
  width = '158px',
  showFileName = true,
  showFileProgress = true,
  barHeight = '12px',
}) => {
  const roundedProgress = Math.round(progress * 100) / 100;

  return (
    <div
      className={`flex w-full items-center ${showFileName ? 'justify-between' : ''} text-slate-200`}
    >
      {showFileName && <div className="text-slate-400">{fileName}</div>}
      <div className={`ml-${showFileName ? '4' : '0'}`} style={{ width }}>
        <div
          className="relative w-full overflow-hidden rounded-full bg-slate-600"
          style={{ height: barHeight }}
        >
          <div
            className="absolute h-full rounded-full bg-slate-400"
            style={{ width: `${progress}%` }}
          />
          <div
            className="absolute inset-0 h-full animate-stripes rounded-full"
            style={{
              width: `${progress}%`,
              backgroundImage: `repeating-linear-gradient(
                45deg,
                rgba(255, 255, 255, 0.3),
                rgba(255, 255, 255, 0.3) 10px,
                transparent 10px,
                transparent 20px
              )`,
            }}
          />
        </div>
      </div>
      {showFileProgress && (
        <div className="ml-4 text-slate-400">{roundedProgress}%</div>
      )}
    </div>
  );
};
