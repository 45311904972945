import React from 'react';

import { Dropdown } from 'ui-kit';

import { RateType } from '__generated__/globalTypes';

import { MaturityDataProps } from './types';
import YieldCurveTable from './YieldCurveTable';

const selectCurveOptions = [
  { label: 'Treasury', value: RateType.RiskFree },
  { label: 'SOFR', value: RateType.Sofr },
];

const MaturityData: React.FC<MaturityDataProps> = ({
  ycChartData,
  setYcChartDataUrl,
  formValues,
  onCurveSelect,
}) => (
  <div className="mb-3 flex items-center justify-between gap-2">
    <YieldCurveTable data={ycChartData || { labels: [], series: {} }} />
    <div className="flex items-center gap-2">
      Select Curve&nbsp;
      <Dropdown
        options={selectCurveOptions}
        value={
          selectCurveOptions.find(
            (option) => option.value === formValues.rateType,
          )?.label || RateType.Sofr
        }
        onValueChange={(option) => {
          setYcChartDataUrl(
            option.value === RateType.Sofr
              ? 'sofr_yc_chart_data.json'
              : 'yc_chart_data.json',
          );
          onCurveSelect({ ...formValues, rateType: option.value });
        }}
      />
    </div>
  </div>
);

export default MaturityData;
