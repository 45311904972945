import React from 'react';

type SellerCounterOfferProps = {
  buyerBid: {
    price: string;
    gwac: string;
    upb: string;
    settleDate: string;
  };
  sellerCounteroffer: {
    price: string;
    gwac: string;
    upb: string;
    settleDate: string;
  };
};

const SellerCounterOffer = ({
  buyerBid,
  sellerCounteroffer,
}: SellerCounterOfferProps): JSX.Element => {
  return (
    <div className="w-full rounded-md bg-brand-950 p-5 font-mono text-foreground-default">
      <div className="grid grid-cols-3 gap-2 border-b border-slate-500 pb-2 text-sm">
        <span className="text-left text-foreground-default">Details</span>
        <span className="text-right text-foreground-default">Your Bid</span>
        <span className="text-left uppercase text-foreground-default">
          Seller Counteroffer
        </span>
      </div>

      <div className="mt-2 divide-y divide-slate-500 text-sm">
        <div className="grid grid-cols-3 gap-4 py-2">
          <span className="text-left text-foreground-default">Price</span>
          <span className="text-right text-foreground-default">
            {buyerBid.price}
          </span>
          <span className="text-left font-bold text-warning-default">
            {sellerCounteroffer.price}
          </span>
        </div>

        <div className="grid grid-cols-3 gap-4 py-2">
          <span className="text-left text-foreground-default">GWAC</span>
          <span className="text-right text-foreground-default">
            {buyerBid.gwac}
          </span>
          <span className="text-left text-warning-default">
            {sellerCounteroffer.gwac}
          </span>
        </div>

        <div className="grid grid-cols-3 gap-4 py-2">
          <span className="text-left text-foreground-default">UPB</span>
          <span className="text-right text-foreground-default">
            {buyerBid.upb}
          </span>
          <span className="text-left text-warning-default">
            {sellerCounteroffer.upb}
          </span>
        </div>

        <div className="grid grid-cols-3 gap-4 py-2">
          <span className="text-left text-foreground-default">Settle Date</span>
          <span className="text-right text-foreground-default">
            {buyerBid.settleDate}
          </span>
          <span className="text-left text-warning-default">
            {sellerCounteroffer.settleDate}
          </span>
        </div>
      </div>
    </div>
  );
};

export default SellerCounterOffer;
