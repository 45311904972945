import React from 'react';

import { useBiddingPricingContext } from '../BiddingPricingContext';
import YieldMatrixContent from './YieldMatrixContent';

const YieldMatrix = () => {
  const {
    selectedCarve,
    bidDetails,
    updateBidDetails,
    cashFlowsDataDisplayProps: { formValues, useSummaryData, onSubmit },
  } = useBiddingPricingContext();

  return (
    <YieldMatrixContent
      selectedCarve={selectedCarve}
      bidDetails={bidDetails}
      updateBidDetails={updateBidDetails}
      formValues={formValues}
      useSummaryData={useSummaryData}
      onSubmit={onSubmit}
    />
  );
};

export default YieldMatrix;
