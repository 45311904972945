import React, { useEffect, useRef, useState } from 'react';

import {
  basisPointsToPercent,
  percentToBasisPoints,
} from 'features/deals/DealStages/EventActionCards/utils';
import { ChartSkeletonLoader } from 'features/pages/market-data/components/ChartSkeleton';
import { URLMapDeprecated } from 'features/pages/market-data/shared';
import MaturityData from 'features/pages/marketplace/BiddingAndPricing/YieldMatrix/MaturityData';
import YieldToPriceChart from 'features/pages/marketplace/BiddingAndPricing/YieldToPriceChart';

import { GetMarketData } from 'query/__generated__/GetMarketData';

import {
  useSelectedPoolCashflowValue,
  useSummaryDataReturnValue,
} from './CashFlows';
import { CashFlowsValidValues } from './configurations/cashflow-inputs';

interface YieldMatrixModelingProps {
  formValues: CashFlowsValidValues;
  useSummaryData: (
    inputValues: CashFlowsValidValues,
  ) => useSummaryDataReturnValue;
  onFormChange: (validatedFormValues: CashFlowsValidValues) => void;
  marketData?: GetMarketData;
  unpaidBalance: number;
}

const labels = [
  '1 Mo',
  '2 Mo',
  '3 Mo',
  '6 Mo',
  '1 Yr',
  '2 Yr',
  '3 Yr',
  '5 Yr',
  '10 Yr',
  '20 Yr',
  '30 Yr',
];

const YieldMatrixModeling: React.FC<YieldMatrixModelingProps> = ({
  formValues,
  useSummaryData,
  onFormChange,
  marketData,
  unpaidBalance = 0,
}) => {
  const [chartWidth, setChartWidth] = useState(800);
  const chartParentRef = useRef<HTMLDivElement>(null);
  const [bidBasisPoints, setBidBasisPoints] = useState(-5000);
  const [ycChartData, setYcChartData] = useState(null);
  const [ycChartDataMap, setYcChartDataMap] = useState<URLMapDeprecated>({});
  const [ycChartDataUrl, setYcChartDataUrl] = useState(
    'sofr_yc_chart_data.json',
  );

  // Fetch market data URLs
  useEffect(() => {
    if (marketData && marketData.marketData) {
      const urlMap: URLMapDeprecated = {};
      Object.entries(marketData.marketData.urlMap).forEach(([key, value]) => {
        urlMap[key] = value;
      });
      setYcChartDataMap(urlMap);
    }
  }, [marketData]);

  // Fetch YC chart data
  useEffect(() => {
    if (ycChartDataUrl && ycChartDataMap[ycChartDataUrl]) {
      fetch(ycChartDataMap[ycChartDataUrl])
        .then((response) => response.json())
        .then((data) => {
          if (ycChartDataUrl === 'sofr_yc_chart_data.json') {
            setYcChartData({
              ...data,
              labels,
            });
          } else {
            setYcChartData(data);
          }
        });
    }
  }, [ycChartDataUrl, ycChartDataMap]);

  useEffect(() => {
    const updateChartWidth = () => {
      if (chartParentRef.current) {
        const parentWidth = chartParentRef.current.offsetWidth;
        setChartWidth(parentWidth - 310);
      }
    };

    updateChartWidth();
    window.addEventListener('resize', updateChartWidth);
    return () => window.removeEventListener('resize', updateChartWidth);
  }, []);

  const bidPx = basisPointsToPercent(bidBasisPoints);

  const { data } = useSummaryData(formValues);

  const handlePointClick = (payload: any) => {
    const price = parseFloat(payload.price.toFixed(3));
    const basisPoints = percentToBasisPoints(price.toString());

    setBidBasisPoints(basisPoints);
  };

  return (
    <div>
      <MaturityData
        ycChartData={ycChartData}
        setYcChartDataUrl={setYcChartDataUrl}
        formValues={formValues}
        onCurveSelect={onFormChange}
      />

      <div className="flex flex-col md:flex-row" ref={chartParentRef}>
        <div className="w-full">
          {data?.cashFlows?.price_yield_matrix ? (
            <YieldToPriceChart
              chartData={data.cashFlows.price_yield_matrix || []}
              upb={unpaidBalance * 100}
              bidPx={bidPx}
              width={chartWidth - 25}
              onPointClick={handlePointClick}
            />
          ) : (
            <ChartSkeletonLoader height={500} width={chartWidth - 25} />
          )}
        </div>
      </div>
    </div>
  );
};

export default YieldMatrixModeling;
