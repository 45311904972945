import { gql } from '@apollo/client';

const WireConfirmationCardFragments = {
  TimelineCard: gql`
    fragment WireConfirmationCard on TimelineCard {
      ... on WireConfirmationCard {
        user_id
        company_id
        user_role
        is_wire_sent
        files_with_notes {
          fileName
          deal {
            id
          }
          s3Key
          notes {
            id
            note
            authorRole
            authorCompany {
              ... on PrivateCompany {
                id
                name
              }
              ... on UserCompany {
                id
                name
              }
            }
            createdAt
            updatedAt
          }
        }
        prices {
          cleanPriceCents
          dirtyPriceCents
          isVisibleToBuyer
        }
        wire_details {
          account_name
          account_number
          bank_name
          routing_number
        }
      }
    }
  `,
};

export default WireConfirmationCardFragments;
