import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
  ChangeEvent,
  DragEvent,
} from 'react';

import { Button, KIND } from 'baseui/button';
import { CheckboxWithLabel } from 'common-ui';
import { Dialog, DialogProps, useDialog } from 'common-ui/Dialog';
import { NumberInputWithUnits } from 'common-ui/inputs/Inputs-styled';
import { performUpload } from 'features/common-elements/useS3Uploader';
import { DateTime } from 'luxon';
import { useDebounce } from 'use-debounce';

import { gql, useApolloClient, useMutation } from '@apollo/client';

import { DealMessageAuthorRole } from '__generated__/globalTypes';

import {
  ConfirmWireTransfer,
  ConfirmWireTransferVariables,
} from 'mutation/__generated__/ConfirmWireTransfer';
import {
  SentWireTransfer,
  SentWireTransferVariables,
} from 'mutation/__generated__/SentWireTransfer';
import { CONFIRM_WIRE_TRANSFER } from 'mutation/confirmWireTransfer';
import { SENT_WIRE_TRANSFER } from 'mutation/sentWireTransfer';

import { GET_DEAL_TIMELINE } from '../../fragments';
import {
  AddWireDetailsNote,
  AddWireDetailsNoteVariables,
} from './__generated__/AddWireDetailsNote';
import {
  GetWireDetailsFileUploadURL,
  GetWireDetailsFileUploadURLVariables,
} from './__generated__/GetWireDetailsFileUploadURL';
import {
  SaveWireDetailsPrices,
  SaveWireDetailsPricesVariables,
} from './__generated__/SaveWireDetailsPrices';
import {
  SetWireDetailsPricesVisible,
  SetWireDetailsPricesVisibleVariables,
} from './__generated__/SetWireDetailsPricesVisible';
import {
  WireConfirmationCard_WireConfirmationCard,
  WireConfirmationCard_WireConfirmationCard_files_with_notes,
} from './__generated__/WireConfirmationCard';
import { WireConfirmationCard_WireConfirmationCard_files_with_notes_notes } from './__generated__/WireConfirmationCard';

const GET_WIRE_DETAILS_UPLOAD_URL = gql`
  mutation GetWireDetailsFileUploadURL($input: WireDetailsFileUploadURLInput!) {
    getWireDetailsFileUploadURL(input: $input) {
      URL
    }
  }
`;

const GET_WIRE_DETAILS_DOWNLOAD_URL = gql`
  query GetWireDetailsFileDownloadURL(
    $input: WireDetailsFileDownloadURLInput!
  ) {
    getWireDetailsFileDownloadURL(input: $input) {
      URL
    }
  }
`;

const REMOVE_WIRE_DETAILS_FILE_MUTATION = gql`
  mutation RemoveWireDetailsFile($input: RemoveWireDetailsFileInput!) {
    removeWireDetailsFile(input: $input) {
      message
    }
  }
`;

const ADD_WIRE_DETAILS_NOTE = gql`
  mutation AddWireDetailsNote($input: AddWireDetailsNoteInput!) {
    addWireDetailsNote(input: $input) {
      id
      note
    }
  }
`;

const SAVE_WIRE_DETAILS_PRICES_MUTATION = gql`
  mutation SaveWireDetailsPrices($input: SaveWireDetailsPricesInput!) {
    saveWireDetailsPrices(input: $input) {
      cleanPriceCents
      dirtyPriceCents
      isVisibleToBuyer
    }
  }
`;

const SET_WIRE_DETAILS_PRICES_VISIBLE_MUTATION = gql`
  mutation SetWireDetailsPricesVisible(
    $input: SetWireDetailsPricesVisibleInput!
  ) {
    setWireDetailsPricesVisible(input: $input) {
      cleanPriceCents
      dirtyPriceCents
      isVisibleToBuyer
    }
  }
`;

interface WireFile {
  fileName: string;
  s3Key: string;
  deal: { id: string };
  notes: WireConfirmationCard_WireConfirmationCard_files_with_notes_notes[];
}

// Hook to upload a single file (PDF only) using our presigned URL API.
const useWireDetailsUpload = (
  dealId: string,
  onError: (error: unknown) => void,
  userId: string,
) => {
  const client = useApolloClient();
  const getPresignedUrl = async (fileName: string) => {
    const { data } = await client.mutate<
      GetWireDetailsFileUploadURL,
      GetWireDetailsFileUploadURLVariables
    >({
      mutation: GET_WIRE_DETAILS_UPLOAD_URL,
      variables: {
        input: {
          dealID: dealId,
          fileName,
        },
      },
    });
    if (!data) {
      throw new Error('Failed to get presigned URL for wire details upload');
    }
    return data.getWireDetailsFileUploadURL.URL;
  };

  const getPresignedUrlRef = useRef<(file: File) => Promise<string>>(
    async () => '',
  );
  useEffect(() => {
    getPresignedUrlRef.current = async (file: File) =>
      getPresignedUrl(file.name);
  }, [getPresignedUrl]);

  const upload = useCallback(
    async (file: File) => {
      if (file.type !== 'application/pdf') {
        throw new Error('Invalid file type');
      }
      try {
        const presignedUrl = await getPresignedUrlRef.current(file);
        await performUpload(
          file,
          presignedUrl,
          () => {},
          false,
          true,
          dealId,
          userId,
        );
      } catch (error) {
        console.error('Failed to upload file:', error);
        onError?.(error);
      }
    },
    [onError, dealId, userId],
  );

  const onFileDrop = async (file: File) => {
    try {
      await upload(file);
    } catch (error) {
      console.error('Error uploading file:', error);
    }
  };

  return {
    onFileDrop,
  };
};

type FileUploadProps = {
  parentId: string;
  onUploadComplete?: () => void;
  userId: string;
};

const FileUpload: React.FC<FileUploadProps> = ({
  parentId,
  onUploadComplete,
  userId,
}) => {
  const { onFileDrop } = useWireDetailsUpload(
    parentId,
    (error) => console.error(error),
    userId,
  );
  const [isDragging, setIsDragging] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleDragOver = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(true);
  };

  const handleDragLeave = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
  };

  const handleDrop = async (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
    const files = e.dataTransfer.files;
    for (let i = 0; i < files.length; i++) {
      await onFileDrop(files[i]);
    }
    onUploadComplete?.();
  };

  const handleFileSelect = async (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      for (let i = 0; i < e.target.files.length; i++) {
        await onFileDrop(e.target.files[i]);
      }
      onUploadComplete?.();
    }
  };

  return (
    <div>
      <div
        className={`border-2 ${
          isDragging ? 'border-blue-500' : 'border-dashed border-gray-300'
        } cursor-pointer p-4 text-center`}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
        onClick={() => fileInputRef.current?.click()}
      >
        Drag and drop files here or click to upload (PDF only)
      </div>
      <input
        type="file"
        accept="application/pdf"
        multiple
        ref={fileInputRef}
        className="hidden"
        onChange={handleFileSelect}
      />
    </div>
  );
};

// A modal dialog for reviewing and adding notes on a wire details file.
interface WireDetailsNotesDialogProps {
  companyId: string;
  userRole: DealMessageAuthorRole;
  file: WireConfirmationCard_WireConfirmationCard_files_with_notes;
  dealId: string;
  dialog: DialogProps;
  onNoteAdded: () => void;
}

const WireDetailsNotesDialog: React.FC<WireDetailsNotesDialogProps> = ({
  companyId,
  userRole,
  file,
  dealId,
  dialog,
  onNoteAdded,
}) => {
  const [notes, setNotes] = useState<
    WireConfirmationCard_WireConfirmationCard_files_with_notes_notes[]
  >(file.notes);
  const [noteText, setNoteText] = useState('');
  const [addWireNote, { loading }] = useMutation<
    AddWireDetailsNote,
    AddWireDetailsNoteVariables
  >(ADD_WIRE_DETAILS_NOTE);

  useEffect(() => {
    setNotes(file.notes);
  }, [file.notes]);

  const handleAddNote = () => {
    if (!noteText.trim()) return;
    const tempId = 'temp-' + Date.now();
    const optimisticNote: WireConfirmationCard_WireConfirmationCard_files_with_notes_notes =
      {
        id: tempId,
        note: noteText,
        authorRole: userRole,
        authorCompany: companyId,
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString(),
      };
    setNotes((prev) => [...prev, optimisticNote]);
    setNoteText('');
    addWireNote({
      variables: {
        input: {
          dealID: dealId,
          fileName: file.fileName,
          note: noteText,
        },
      },
      optimisticResponse: {
        addWireDetailsNote: {
          id: tempId,
          note: noteText,
        },
      },
    })
      .then((result) => {
        if (result.data?.addWireDetailsNote) {
          const realNote = result.data.addWireDetailsNote;
          setNotes((prev) =>
            prev.map((n) => (n.id === tempId ? { ...n, id: realNote.id } : n)),
          );
          onNoteAdded();
        }
      })
      .catch((error) => {
        setNotes((prev) => prev.filter((n) => n.id !== tempId));
        console.error('Error adding note', error);
      });
  };

  return (
    <Dialog dialog={dialog}>
      <button
        className="absolute right-2 top-2 text-gray-500 hover:text-gray-300"
        onClick={() => dialog.setIsOpen(false)}
      >
        ✕
      </button>
      <h2 className="mb-4 text-center text-xl font-semibold">
        Notes for “{file.fileName}”
      </h2>
      <div className="mt-4 flex max-h-96 flex-col gap-2 overflow-y-auto">
        {notes.map((note) => {
          const align =
            note.authorRole === 'BUYER' ? 'justify-end' : 'justify-start';
          const textAlign =
            note.authorRole === 'BUYER' ? 'text-right' : 'text-left';
          return (
            <div key={note.id} className={`flex ${align}`}>
              <div className={`max-w-[80%] ${textAlign}`}>
                <div className="text-gray-500">{note.authorRole}</div>
                <div className="font-pt-sans-narrow text-xs text-slate-200">
                  {DateTime.fromISO(note.createdAt).toLocaleString(
                    DateTime.DATETIME_MED,
                  )}
                </div>
                <div className="text-sm">{note.note}</div>
              </div>
            </div>
          );
        })}
      </div>
      <textarea
        className="my-4 h-28 w-full rounded-md border border-pink-400 bg-background-surface p-2 text-sm text-foreground-default focus:border-pink-400 focus:outline-none"
        aria-label="Add Note"
        value={noteText}
        onChange={(e) => setNoteText(e.target.value)}
      />
      <div className="flex justify-between gap-2">
        <button
          className="btn-secondary"
          onClick={() => dialog.setIsOpen(false)}
        >
          Cancel
        </button>
        <button
          className="btn-primary"
          disabled={!noteText.trim() || loading}
          onClick={handleAddNote}
        >
          Add Note
        </button>
      </div>
    </Dialog>
  );
};

// The main card body component.
type WireConfirmationCardBodyProps = {
  dealId: string;
} & WireConfirmationCard_WireConfirmationCard;

const WireConfirmationCardBody: React.FC<WireConfirmationCardBodyProps> = (
  props,
) => {
  const [isSelected, setIsSelected] = useState(false);
  const [sendWireConfirmation] = useMutation<
    SentWireTransfer,
    SentWireTransferVariables
  >(SENT_WIRE_TRANSFER);
  const [confirmWireTransfer] = useMutation<
    ConfirmWireTransfer,
    ConfirmWireTransferVariables
  >(CONFIRM_WIRE_TRANSFER);
  const [saveWireDetailsPrices] = useMutation<
    SaveWireDetailsPrices,
    SaveWireDetailsPricesVariables
  >(SAVE_WIRE_DETAILS_PRICES_MUTATION);
  const [setWireDetailsPricesVisible] = useMutation<
    SetWireDetailsPricesVisible,
    SetWireDetailsPricesVisibleVariables
  >(SET_WIRE_DETAILS_PRICES_VISIBLE_MUTATION);
  const [removeWireDetailsFile] = useMutation(
    REMOVE_WIRE_DETAILS_FILE_MUTATION,
  );

  const client = useApolloClient();

  const dialog = useDialog();

  const shouldShowCheckbox = props.is_wire_sent;
  const optionalCheckbox = (
    <CheckboxWithLabel
      checked={isSelected}
      onClick={() => setIsSelected((previous) => !previous)}
    >
      I have completed the transaction
    </CheckboxWithLabel>
  );

  const handleSubmit = () => {
    if (isSelected) {
      sendWireConfirmation({
        variables: {
          input: {
            deal_id: props.dealId,
          },
        },
        refetchQueries: [
          {
            query: GET_DEAL_TIMELINE,
            variables: { id: props.dealId },
          },
        ],
      });
    } else if (!shouldShowCheckbox) {
      confirmWireTransfer({
        variables: {
          input: {
            deal_id: props.dealId,
          },
        },
        refetchQueries: [
          {
            query: GET_DEAL_TIMELINE,
            variables: { id: props.dealId },
          },
        ],
      });
    }
  };

  const wireDetails = props.wire_details;

  // When a file is uploaded or a note is added, refetch the deal timeline.
  const refetchTimeline = () => {
    client.refetchQueries({
      include: [GET_DEAL_TIMELINE],
    });
  };

  // Download a file by fetching its presigned URL and opening it.
  const handleDownload = async (file: WireFile) => {
    try {
      const { data } = await client.query({
        query: GET_WIRE_DETAILS_DOWNLOAD_URL,
        variables: {
          input: {
            dealID: props.dealId,
            fileName: file.fileName,
          },
        },
        fetchPolicy: 'network-only',
      });
      if (
        data &&
        data.getWireDetailsFileDownloadURL &&
        data.getWireDetailsFileDownloadURL.URL
      ) {
        window.open(data.getWireDetailsFileDownloadURL.URL, '_blank');
      } else {
        console.error('Download URL not found');
      }
    } catch (error) {
      console.error('Error downloading file:', error);
    }
  };

  // Remove a file if the user is the seller.
  const handleRemove = async (file: WireFile) => {
    try {
      await removeWireDetailsFile({
        variables: {
          input: {
            dealID: props.dealId,
            fileName: file.fileName,
          },
        },
        refetchQueries: [
          {
            query: GET_DEAL_TIMELINE,
            variables: { id: props.dealId },
          },
        ],
      });
    } catch (error) {
      console.error('Error removing file:', error);
    }
  };

  // Local state to control which file’s notes dialog is open.
  const [selectedFileForNotes, setSelectedFileForNotes] =
    useState<WireFile | null>(null);

  // State to hold price inputs as dollars (string format)
  const [cleanPrice, setCleanPrice] = useState('');
  const [dirtyPrice, setDirtyPrice] = useState('');

  // When prices change in props, update our input fields (if prices are not yet sent)
  useEffect(() => {
    if (props.prices && !props.prices.isVisibleToBuyer) {
      setCleanPrice(
        props.prices?.cleanPriceCents
          ? (props.prices.cleanPriceCents / 100).toFixed(2)
          : '',
      );
      setDirtyPrice(
        props.prices?.dirtyPriceCents
          ? (props.prices.dirtyPriceCents / 100).toFixed(2)
          : '',
      );
    }
  }, [props.prices]);

  const [debouncedCleanPrice] = useDebounce(cleanPrice, 500);
  const [debouncedDirtyPrice] = useDebounce(dirtyPrice, 500);

  useEffect(() => {
    if (!props.prices || props.prices.isVisibleToBuyer) return;
    const cp = parseFloat(debouncedCleanPrice);
    const dp = parseFloat(debouncedDirtyPrice);
    if (!isNaN(cp) && !isNaN(dp)) {
      const cleanCents = Math.round(cp * 100);
      const dirtyCents = Math.round(dp * 100);
      if (
        cleanCents !== props.prices.cleanPriceCents ||
        dirtyCents !== props.prices.dirtyPriceCents
      ) {
        saveWireDetailsPrices({
          variables: {
            input: {
              dealID: props.dealId,
              cleanPriceCents: cleanCents,
              dirtyPriceCents: dirtyCents,
            },
          },
        });
      }
    }
  }, [
    debouncedCleanPrice,
    debouncedDirtyPrice,
    props.prices,
    props.dealId,
    saveWireDetailsPrices,
  ]);

  const handleSendPrices = async () => {
    try {
      await setWireDetailsPricesVisible({
        variables: { input: { dealID: props.dealId } },
        refetchQueries: [
          { query: GET_DEAL_TIMELINE, variables: { id: props.dealId } },
        ],
      });
    } catch (error) {
      console.error('Error sending prices', error);
    }
  };

  return (
    <div className="flex flex-wrap gap-6">
      <div className="w-full">
        <FileUpload
          parentId={props.dealId}
          onUploadComplete={refetchTimeline}
          userId={props.user_id}
        />
      </div>
      <div className="w-full">
        <h3 className="mb-2 text-lg font-semibold">Uploaded Files</h3>
        <ul className="divide-y divide-slate-600">
          {props.files_with_notes.map((file, index) => (
            <li
              key={index}
              className="flex items-center justify-between px-3 py-2"
            >
              <span>{file.fileName}</span>
              <div className="flex gap-2">
                <button
                  onClick={() => handleDownload(file)}
                  className="text-blue-500 hover:text-blue-700"
                >
                  ⬇️
                </button>
                <button
                  onClick={() => {
                    setSelectedFileForNotes(file);
                    dialog.openDialog();
                  }}
                  className="text-green-500 hover:text-green-700"
                >
                  📝
                </button>
                {props.user_role === 'SELLER' && (
                  <button
                    onClick={() => handleRemove(file)}
                    className="text-red-500 hover:text-red-700"
                  >
                    🗑️
                  </button>
                )}
              </div>
            </li>
          ))}
        </ul>
      </div>
      {props.prices && (
        <div className="mt-4 w-full rounded border bg-gray-800 p-4">
          <h3 className="mb-2 text-lg font-semibold text-white">Prices</h3>
          {props.prices.isVisibleToBuyer ? (
            <div className="text-white">
              <p>
                Clean Price: ${' '}
                {((props.prices.cleanPriceCents || 0) / 100).toFixed(2)}
              </p>
              <p>
                Dirty Price: ${' '}
                {((props.prices.dirtyPriceCents || 0) / 100).toFixed(2)}
              </p>
            </div>
          ) : (
            <div className="flex flex-col gap-2">
              <div className="flex gap-2">
                <label className="block text-white">
                  Clean Price
                  <NumberInputWithUnits
                    unit="$"
                    leftAligned={true}
                    value={cleanPrice}
                    onChange={(e) => setCleanPrice(e.target.value)}
                    className="ml-2 border p-1"
                    error={props.prices.cleanPriceCents === null}
                  />
                </label>
                <label className="block text-white">
                  Dirty Price
                  <NumberInputWithUnits
                    unit="$"
                    leftAligned={true}
                    value={dirtyPrice}
                    onChange={(e) => setDirtyPrice(e.target.value)}
                    className="ml-2 border p-1"
                    error={props.prices.dirtyPriceCents === null}
                  />
                </label>
              </div>
              <Button kind={KIND.primary} onClick={handleSendPrices}>
                Send Prices
              </Button>
            </div>
          )}
        </div>
      )}
      {wireDetails && (
        <>
          <div className="flex-1">
            <ul className="m-0 list-none p-0">
              <li className="flex justify-between border-t border-slate-600 px-3 py-2">
                <span className="text-gray-800">Account Name</span>
                <span className="text-slate-100">
                  {wireDetails.account_name}
                </span>
              </li>
              <li className="flex justify-between border-t border-slate-600 px-3 py-2">
                <span className="text-gray-800">Account Number</span>
                <span className="text-slate-100">
                  {wireDetails.account_number}
                </span>
              </li>
            </ul>
          </div>
          <div className="flex-1">
            <ul className="m-0 list-none p-0">
              <li className="flex justify-between border-t border-slate-600 px-3 py-2">
                <span className="text-gray-800">Bank Name</span>
                <span className="text-slate-100">{wireDetails.bank_name}</span>
              </li>
              <li className="flex justify-between border-t border-slate-600 px-3 py-2">
                <span className="text-gray-800">Routing Number</span>
                <span className="text-slate-100">
                  {wireDetails.routing_number}
                </span>
              </li>
            </ul>
          </div>
        </>
      )}
      {shouldShowCheckbox && (
        <div className="mt-4 w-full">{optionalCheckbox}</div>
      )}
      {!props.is_wire_sent && (
        <div className="mt-4 w-full">
          <Button
            kind={KIND.primary}
            disabled={!isSelected && shouldShowCheckbox}
            onClick={handleSubmit}
          >
            Confirm
          </Button>
        </div>
      )}
      {selectedFileForNotes && (
        <WireDetailsNotesDialog
          companyId={props.company_id}
          userRole={props.user_role}
          file={selectedFileForNotes}
          dealId={props.dealId}
          dialog={dialog}
          onNoteAdded={refetchTimeline}
        />
      )}
    </div>
  );
};

export default WireConfirmationCardBody;
