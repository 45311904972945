import React, { useMemo } from 'react';

type DealButtonProps = {
  label: string;
  children?: React.ReactNode;
  onClick?: () => void;
  className?: string;
  Icon?: React.ReactNode;
  iconPosition?: 'left' | 'right';
  variant?: 'primary' | 'success' | 'reject' | 'dark';
  disabled?: boolean;
  type?: 'button' | 'submit' | 'reset';
};

const variantClasses = {
  primary:
    'text-brand-950 bg-brand-400 hover:text-white hover:bg-brand-800 border-none',
  success:
    'text-brand-950 bg-green-400 hover:text-white hover:bg-green-600 border-none',
  reject: 'text-red-400 hover:bg-red-400 hover:text-white border-red-400',
  dark: 'text-white bg-grey-950 border-gray-700 hover:bg-background-canvas hover:border-red-500',
};

const disabledVariantClasses = {
  primary: 'text-gray-500 bg-gray-800 border-none',
  success: 'text-brand-950 bg-green-400 border-none',
  reject: 'text-red-400 border-red-400',
  dark: 'text-white bg-grey-950 border-gray-700',
};

const DealButton: React.FC<DealButtonProps> = ({
  label,
  children,
  onClick,
  className = '',
  Icon,
  iconPosition = 'left',
  variant = 'primary',
  disabled = false,
  type = 'button',
}) => {
  const baseClasses = useMemo(
    () =>
      `inline-flex items-center gap-4 py-2.5 px-4 rounded font-heebo text-sm font-medium border
       transition-all duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2
       ${disabled ? disabledVariantClasses[variant] : variantClasses[variant]} 
       ${className}`,
    [variant, className, disabled],
  );

  const disabledClasses = disabled ? 'cursor-not-allowed' : '';

  return (
    <button
      aria-label={label}
      onClick={disabled ? undefined : onClick}
      className={`${baseClasses} ${disabledClasses}`}
      disabled={disabled}
      type={type}
    >
      {iconPosition === 'left' && Icon}
      {children}
      {iconPosition === 'right' && Icon}
    </button>
  );
};

export default React.memo(DealButton);
