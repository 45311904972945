import React from 'react';

import { IconInfoCircle } from '@tabler/icons-react';
import ESignIcon from 'assets/pngs/ESignModal.png';
import { BaseButton } from 'common-ui/Buttons/BaseButton/BaseButton';
import { Formik, Form, Field } from 'formik';
import { ModalNotStyled } from 'ui-kit';
import { LinkButton } from 'ui-kit';
import * as Yup from 'yup';

const SignSettingsSchema = Yup.object().shape({
  sellerReference: Yup.string().required('Required'),
  buyerReference: Yup.string().required('Required'),
});

interface ContractReferencesModalProps {
  initialValues: { sellerReference: string; buyerReference: string };
  onSubmit: (values: {
    sellerReference: string;
    buyerReference: string;
  }) => void;
  trigger: React.ReactElement;
}

const ContractReferencesModal: React.FC<ContractReferencesModalProps> = ({
  initialValues,
  onSubmit,
  trigger,
}) => {
  let formSubmitHandler: (() => void) | null = null; // This will hold the submit function reference

  return (
    <ModalNotStyled trigger={trigger}>
      {({ closeModal }) => (
        <div className="w-full max-w-[400px] rounded-lg border border-pink-500 bg-background-canvas p-6 font-heebo text-white shadow-lg">
          <div className="flex justify-end">
            <button
              className="text-grey-500 hover:text-grey-300"
              onClick={closeModal}
            >
              ✕
            </button>
          </div>
          <div className="flex flex-col items-center justify-between">
            <img src={ESignIcon} alt="ESign Icon" className="h-80 w-80" />
          </div>
          <h2 className="mb-4 text-center text-xl font-semibold">
            Contract Signatures Text References
          </h2>
          <p className="mb-4 text-center text-sm">
            Enter each text reference you’ve identified in your contract
            document for both the seller’s and buyer’s signature.
          </p>

          <Formik
            initialValues={initialValues}
            validationSchema={SignSettingsSchema}
            onSubmit={(values, { resetForm }) => {
              onSubmit(values);
              resetForm();
              closeModal();
            }}
          >
            {({ errors, touched, submitForm }) => {
              formSubmitHandler = submitForm;

              return (
                <Form>
                  <div className="mb-4">
                    <label className="mb-1 block text-sm">
                      Seller’s signature reference text
                    </label>
                    <Field
                      name="sellerReference"
                      placeholder="<seller-reference-text>"
                      className="w-full rounded border border-slate-700 bg-slate-800 p-2 text-white"
                    />
                    {errors.sellerReference && touched.sellerReference ? (
                      <div className="text-sm text-red-500">
                        {errors.sellerReference}
                      </div>
                    ) : null}
                  </div>
                  <div className="mb-4">
                    <label className="mb-1 block text-sm">
                      Counterparty’s signature reference text
                    </label>
                    <Field
                      name="buyerReference"
                      placeholder="<buyer-reference-text>"
                      className="w-full rounded border border-slate-700 bg-slate-800 p-2 text-white"
                    />
                    {errors.buyerReference && touched.buyerReference ? (
                      <div className="text-sm text-red-500">
                        {errors.buyerReference}
                      </div>
                    ) : null}
                  </div>
                  <div className="flex justify-end">
                    <BaseButton
                      type="primary"
                      className="bg-purple-600 text-white"
                      label="save"
                      onClick={() => {
                        if (formSubmitHandler) {
                          formSubmitHandler();
                        }
                      }}
                    >
                      Save
                    </BaseButton>
                  </div>
                </Form>
              );
            }}
          </Formik>
          <LinkButton
            text="See example document"
            url=""
            icon={<IconInfoCircle stroke={2} />}
            iconPosition="before"
          />
        </div>
      )}
    </ModalNotStyled>
  );
};

export default ContractReferencesModal;
