import { ChangeEvent } from 'react';

export const handleOnchange = (
  event: ChangeEvent<HTMLInputElement>,
  min: number | undefined,
  max: number | undefined,
  onChange: (e: ChangeEvent<HTMLInputElement>) => void,
  name = '',
) => {
  const value = parseInt(event.target.value);
  if (min?.toString() && max?.toString()) {
    if (value <= max && value >= min) {
      onChange({
        ...event,
        target: {
          ...event.target,
          name: name,
        },
      });
      return;
    }
    if (value >= max) {
      event.target.value = max.toString();
      onChange({
        ...event,
        target: {
          ...event.target,
          value: max.toString(),
          name: name,
        },
      });
      return;
    }
    if (value <= min) {
      onChange({
        ...event,
        target: {
          ...event.target,
          value: min.toString(),
          name: name,
        },
      });
      return;
    }
  }
  if (min?.toString()) {
    onChange({
      ...event,
      target: {
        ...event.target,
        value: value <= min ? min.toString() : event.target.value,
        name: name,
      },
    });
  }
  if (max?.toString()) {
    onChange({
      ...event,
      target: {
        ...event.target,
        value: value >= max ? max.toString() : event.target.value,
        name: name,
      },
    });
  }
  if (!min?.toString() && !max?.toString()) {
    onChange({
      ...event,
      target: {
        ...event.target,
        name: name,
      },
    });
  }
};
