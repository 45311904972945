import React, { useEffect, useState, useRef } from 'react';

import {
  basisPointsToPercent,
  percentToBasisPoints,
} from 'features/deals/DealStages/EventActionCards/utils';
import { CashFlowModelingInputs } from 'features/drilldown/cashflows/CashFlowModelingInputs';
import { ChartSkeletonLoader } from 'features/pages/market-data/components/ChartSkeleton';
import { URLMapDeprecated } from 'features/pages/market-data/shared';

import { useQuery } from '@apollo/client';

import { StipulationFieldName } from '__generated__/globalTypes';

import { GetMarketData } from 'query/__generated__/GetMarketData';
import { GET_MARKET_DATA } from 'query/getMarketData';

import { useBiddingPricingContext } from '../BiddingPricingContext';
import BidSummary, { BidSummaryInfo } from '../BidSummary';
import MaturityData from './MaturityData';
import { binarySearchMatrix, isWithinOrderedMatrix } from './utils';
import YieldToPriceChart from '../YieldToPriceChart';
import { YieldMatrixContentProps } from './types';

export const YieldMatrixContent: React.FC<YieldMatrixContentProps> = ({
  selectedCarve,
  bidDetails,
  updateBidDetails,
  formValues,
  useSummaryData,
  onSubmit,
}) => {
  const [ycChartData, setYcChartData] = useState(null);
  const { data } = useSummaryData(formValues);

  const chartParentRef = useRef<HTMLDivElement>(null);
  const [chartWidth, setChartWidth] = useState<number>(800);

  const [ycChartDataMap, setYcChartDataMap] = useState<URLMapDeprecated>(
    {} as URLMapDeprecated,
  );
  const [ycChartDataUrl, setYcChartDataUrl] = useState(
    'sofr_yc_chart_data.json',
  );
  const { data: marketData } = useQuery<GetMarketData>(GET_MARKET_DATA, {
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    if (marketData && marketData.marketData) {
      const urlMap: URLMapDeprecated = {};
      Object.entries(marketData.marketData.urlMap).forEach(([key, value]) => {
        urlMap[key] = value;
      });
      setYcChartDataMap(urlMap);
    }
  }, [marketData]);

  useEffect(() => {
    if (ycChartDataUrl) {
      fetch(ycChartDataMap[ycChartDataUrl]).then((response) => {
        response.json().then((data) => {
          if (ycChartDataUrl === 'sofr_yc_chart_data.json') {
            const transformedData = {
              ...data,
              labels: [
                '1 Mo',
                '2 Mo',
                '3 Mo',
                '6 Mo',
                '1 Yr',
                '2 Yr',
                '3 Yr',
                '5 Yr',
                '10 Yr',
                '20 Yr',
                '30 Yr',
              ],
            };
            setYcChartData(transformedData);
          } else {
            setYcChartData(data);
          }
        });
      });
    }
  }, [ycChartDataUrl, ycChartDataMap]);

  const carveSummary = selectedCarve?.carve_summary;

  useEffect(() => {
    const updateChartWidth = () => {
      if (chartParentRef.current) {
        const parentWidth = chartParentRef.current.offsetWidth;
        setChartWidth(parentWidth - 310);
      }
    };

    updateChartWidth();
    window.addEventListener('resize', updateChartWidth);

    return () => {
      window.removeEventListener('resize', updateChartWidth);
    };
  }, [chartParentRef]);

  if (!bidDetails || !carveSummary) return null;

  const bidBasisPoints = bidDetails?.carve?.stipulations?.bid_basis_points || 0;
  const bidPx = basisPointsToPercent(bidBasisPoints);
  console.log(bidPx, 2222);

  const bidSummary: BidSummaryInfo = {
    purchaseDollarPrice: 0,
  };

  if (
    data != null &&
    'price_yield_matrix' in data.cashFlows &&
    data.cashFlows.price_yield_matrix != null
  ) {
    const priceYieldMatrix = data.cashFlows.price_yield_matrix;
    const bidPrice = parseFloat(basisPointsToPercent(bidBasisPoints)) / 100;
    const purchasePriceCents = bidPrice * carveSummary.unpaidBalance;
    bidSummary.purchaseDollarPrice = purchasePriceCents;
    if (
      isWithinOrderedMatrix(purchasePriceCents, priceYieldMatrix.spread_matrix)
    ) {
      const spreadMatrix = priceYieldMatrix.spread_matrix;
      const spreadData = binarySearchMatrix(spreadMatrix, purchasePriceCents);
      bidSummary.spread = spreadData.rate * 10_000;
      bidSummary.spreadDuration = spreadData.duration;
    }
    if (
      isWithinOrderedMatrix(purchasePriceCents, priceYieldMatrix.yield_matrix)
    ) {
      const yieldMatrix = priceYieldMatrix.yield_matrix;
      const yieldData = binarySearchMatrix(yieldMatrix, purchasePriceCents);
      bidSummary.netYield = yieldData.rate * 100;
      bidSummary.yieldDuration = yieldData.duration;
    }
  }

  const handlePointClick = (payload: any) => {
    const price = parseFloat(payload.price.toFixed(3));
    const basisPoints = percentToBasisPoints(price.toString());

    updateBidDetails(
      {
        ...bidDetails,
        carve: {
          ...bidDetails.carve,
          stipulations: {
            ...bidDetails.stipulations,
            bid_basis_points: basisPoints,
          },
        },
      },
      StipulationFieldName.bid_basis_points,
      basisPoints.toString(),
    );
  };

  return (
    <>
      <div
        style={{
          paddingBottom: 15,
          gap: 40,
          display: 'flex',
        }}
      >
        <CashFlowModelingInputs
          formValues={formValues}
          onSubmit={onSubmit}
          withServicingRate
        />
      </div>

      <MaturityData
        ycChartData={ycChartData}
        setYcChartDataUrl={setYcChartDataUrl}
        formValues={formValues}
        onCurveSelect={onSubmit}
      />

      <div className="flex flex-col gap-8 md:flex-row" ref={chartParentRef}>
        <div style={{ width: `${chartWidth}px` }} className="w-full">
          {data != null && 'price_yield_matrix' in data.cashFlows ? (
            <YieldToPriceChart
              chartData={data.cashFlows.price_yield_matrix}
              upb={carveSummary.unpaidBalance}
              bidPx={bidPx}
              width={chartWidth - 25}
              onPointClick={handlePointClick}
            />
          ) : (
            <ChartSkeletonLoader height={500} width={chartWidth - 25} />
          )}
        </div>

        <div className="min-w-[300px] pr-4">
          <BidSummary carve={carveSummary} bidSummary={bidSummary} />
        </div>
      </div>
    </>
  );
};

export default YieldMatrixContent;
