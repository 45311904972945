import { DateTime } from 'luxon';

// counts the difference between new Date() and pasted date in SECONDS!
export const formatTimeDifference = (timestamp: number): string => {
  const now = DateTime.now();
  const past = DateTime.fromSeconds(timestamp);

  const diff = now
    .diff(past, ['years', 'months', 'weeks', 'days', 'hours', 'minutes'])
    .toObject();

  switch (true) {
    case !diff.minutes:
      return 'Now';
    case !diff.hours && diff.minutes && diff.minutes < 60:
      return `${Math.floor(diff.minutes!)}m ago`;
    case !diff.days && diff.hours && diff.hours < 24:
      return `${Math.floor(diff.hours!)}h ${Math.floor(diff.minutes! % 60)}m ago`;
    case !diff.weeks && diff.days && diff.days < 7:
      return `${Math.floor(diff.days!)}d ${Math.floor(diff.hours! % 24)}h ago`;
    case !diff.months && diff.weeks && diff.days! < 30:
      return `${Math.floor(diff.weeks!)}w ${Math.floor(diff.days! % 7)}d ${Math.floor(diff.hours! % 24)}h ago`;
    case !diff.years && diff.months && diff.months < 12:
      return `${Math.floor(diff.months!)}mo ${Math.floor(diff.days!)}d ago`;

    default:
      return `${Math.floor(diff.years!)}y ${Math.floor(diff.months!)}mo ${Math.floor(diff.days!)}d ago`;
  }
};
